import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CIcon = _resolveComponent("CIcon")!
  const _component_CHeaderToggler = _resolveComponent("CHeaderToggler")!
  const _component_CHeaderBrand = _resolveComponent("CHeaderBrand")!
  const _component_CContainer = _resolveComponent("CContainer")!
  const _component_CHeaderDivider = _resolveComponent("CHeaderDivider")!
  const _component_AppBreadcrumb = _resolveComponent("AppBreadcrumb")!
  const _component_CHeader = _resolveComponent("CHeader")!

  return (_openBlock(), _createBlock(_component_CHeader, {
    position: "sticky",
    class: "mb-4"
  }, {
    default: _withCtx(() => [
      (!$setup.sidebarVisible)
        ? (_openBlock(), _createBlock(_component_CContainer, {
            key: 0,
            fluid: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CHeaderToggler, {
                class: "ps-1",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.commit('toggleSidebar')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CIcon, {
                    icon: "cil-menu",
                    size: "lg"
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_CHeaderBrand, {
                class: "mx-auto",
                to: "/"
              }, {
                default: _withCtx(() => [
                  (!_ctx.$store.state.sidebarVisible)
                    ? (_openBlock(), _createBlock(_component_CIcon, {
                        key: _ctx.$store.state.theme,
                        icon: _ctx.$store.state.theme === 'default' ? $setup.logo : $setup.logoNegative,
                        height: "20",
                        alt: "Logo"
                      }, null, 8, ["icon"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_CHeaderToggler, { class: "px-md-0 me-md-3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_CIcon, {
                    icon: "cil-applications-settings",
                    size: "lg",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$store.commit('toggleAside')))
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!$setup.sidebarVisible)
        ? (_openBlock(), _createBlock(_component_CHeaderDivider, { key: 1 }))
        : _createCommentVNode("", true),
      _createVNode(_component_CContainer, { fluid: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_AppBreadcrumb)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}