import { App, Plugin, ref } from 'vue'
import { Toast } from '@/types/toast'

export const ToastPlugin: Plugin = {
  install: (app: App) => {
    const toast = (toast: Toast) => {
      app.config.globalProperties.$toasts.value.push(toast)
    }

    app.provide('toast', toast)

    app.config.globalProperties.$toasts = ref([])

    const getToasts = () => {
      return app.config.globalProperties.$toasts
    }

    app.provide('getToasts', getToasts)
  },
}
