export function parseDate(dateString) {
  const date = new Date(dateString)
  if (
    date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() ==
    '0:0:0'
  ) {
    return date.toLocaleDateString(navigator.language)
  } else {
    return date.toLocaleString(navigator.language)
  }
}
