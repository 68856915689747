export default [
  {
    component: 'CNavItem',
    name: 'dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
  // {
  //   component: 'CNavTitle',
  //   name: 'Account naam',
  //   icon: '',
  // },
  {
    component: 'CNavItem',
    name: 'account',
    to: '/account',
    icon: 'cil-people',
  },
  {
    component: 'CNavTitle',
    name: 'service',
    icon: '',
  },
  // {
  //   component: 'CNavGroup',
  //   name: 'Products',
  //   icon: 'cil-box',
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'VPS',
  //       icon: 'cil-cloud',
  //       // to: '/',
  //       to: '/products/vps',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Hosting',
  //       icon: 'cil-screen-desktop',
  //       // to: '/',
  //       to: '/products/hosting',
  //     },
  //   ],
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Website',
  //   icon: 'cil-browser',
  //   // to: '/',
  //   to: '/products/website',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Domain',
  //   icon: 'cil-globe',
  //   // to: '/',
  //   to: '/products/domain',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'DNS',
  //   icon: 'cil-dns',
  //   // to: '/',
  //   to: '/products/dns',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'SSL',
  //   icon: 'cil-lock-locked',
  //   // to: '/',
  //   to: '/products/ssl',
  // },
  {
    component: 'CNavItem',
    name: 'email',
    icon: 'cil-mail',
    to: '/service/mail',
  },
  {
    component: 'CNavItem',
    name: 'dns',
    icon: 'cil-dns',
    to: '/service/dns',
    roles: ['superadmin'],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'hosting',
  //   icon: 'cil-screen-desktop',
  //   to: '/service/hosting',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Backup',
  //   icon: 'cil-restore',
  //   // to: '/',
  //   to: '/products/backup',
  // },
  // {
  //   component: 'CNavTitle',
  //   name: 'Migration tools',
  //   icon: '',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Assistant',
  //   icon: 'cib-probot',
  //   // to: '/',
  //   to: '/tools/migration/assistant',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Domain',
  //   icon: 'cil-globe',
  //   // to: '/',
  //   to: '/tools/migration/domain',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'DNS',
  //   icon: 'cil-dns',
  //   // to: '/',
  //   to: '/tools/migration/dns',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Website',
  //   icon: 'cil-browser',
  //   // to: '/',
  //   to: '/tools/migration/site',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Email',
  //   icon: 'cil-mail',
  //   // to: '/',
  //   to: '/tools/migration/email',
  // },
  // {
  //   component: 'CNavTitle',
  //   name: 'Developer tools',
  //   icon: '',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Gitlab',
  //   icon: 'cil-restore',
  //   // to: '/',
  //   to: '/products/backup',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Dev host',
  //   icon: 'cil-restore',
  //   // to: '/',
  //   to: '/products/backup',
  // },
]
