<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid v-if="!sidebarVisible">
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto" to="/">
        <CIcon
          :key="$store.state.theme"
          :icon="$store.state.theme === 'default' ? logo : logoNegative"
          height="20"
          alt="Logo"
          v-if="!$store.state.sidebarVisible"
        />
      </CHeaderBrand>
      <!-- <CHeaderNav class="ms-auto me-4">
        <CButtonGroup aria-label="Theme switch">
          <CFormCheck
            type="radio"
            :button="{ color: 'primary' }"
            name="theme-switch"
            id="btn-light-theme"
            autoComplete="off"
            :checked="$store.state.theme === 'default'"
            @change="
              (event) =>
                $store.commit({
                  type: 'toggleTheme',
                  value: 'default',
                })
            "
          >
            <template #label><CIcon icon="cil-sun" /></template>
          </CFormCheck>
          <CFormCheck
            type="radio"
            :button="{ color: 'primary' }"
            name="theme-switch"
            id="btn-dark-theme"
            autoComplete="off"
            :checked="$store.state.theme === 'dark'"
            @change="
              (event) =>
                $store.commit({
                  type: 'toggleTheme',
                  value: 'dark',
                })
            "
          >
            <template #label><CIcon icon="cil-moon" /></template>
          </CFormCheck>
        </CButtonGroup>
      </CHeaderNav> -->
      <CHeaderToggler class="px-md-0 me-md-3">
        <CIcon
          icon="cil-applications-settings"
          size="lg"
          @click="$store.commit('toggleAside')"
        />
      </CHeaderToggler>
    </CContainer>
    <CHeaderDivider v-if="!sidebarVisible" />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script lang="ts">
import AppBreadcrumb from './AppBreadcrumb.vue'
import { logo } from '@/assets/brand/logo-cacholong'
import { logoNegative } from '@/assets/brand/logo-cacholong-negative'
import { useStore } from 'vuex'
import { authStore } from '@/store/authStore'
import { computed } from 'vue'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const store = useStore()
    const astore = authStore()

    return {
      logo,
      logoNegative,
      account: computed(() => {
        return astore.account
      }),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
