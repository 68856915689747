import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CIcon = _resolveComponent("CIcon")!
  const _component_CSidebarBrand = _resolveComponent("CSidebarBrand")!
  const _component_AppSidebarNav = _resolveComponent("AppSidebarNav")!
  const _component_AppContact = _resolveComponent("AppContact")!
  const _component_CNavItem = _resolveComponent("CNavItem")!
  const _component_CNav = _resolveComponent("CNav")!
  const _component_CAvatar = _resolveComponent("CAvatar")!
  const _component_CDropdownToggle = _resolveComponent("CDropdownToggle")!
  const _component_AppHeaderDropdownAccnt = _resolveComponent("AppHeaderDropdownAccnt")!
  const _component_CSidebarFooter = _resolveComponent("CSidebarFooter")!
  const _component_CSidebar = _resolveComponent("CSidebar")!

  return (_openBlock(), _createBlock(_component_CSidebar, {
    position: "fixed",
    selfHiding: "md",
    unfoldable: false,
    visible: $setup.sidebarVisible,
    onVisibleChange: _cache[0] || (_cache[0] = 
      (event) =>
        _ctx.$store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    )
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CSidebarBrand, null, {
        default: _withCtx(() => [
          _createVNode(_component_CIcon, {
            customClassName: "sidebar-brand-full",
            icon: $setup.logoNegative,
            height: 35,
            width: 180
          }, null, 8, ["icon"]),
          _createVNode(_component_CIcon, {
            customClassName: "sidebar-brand-narrow",
            icon: $setup.sygnetNegative,
            height: 35
          }, null, 8, ["icon"])
        ]),
        _: 1
      }),
      (_openBlock(), _createBlock(_component_AppSidebarNav, {
        accountName: $setup.account.display_name,
        roles: $setup.roles,
        key: $setup.account.display_name
      }, null, 8, ["accountName", "roles"])),
      _createVNode(_component_CNav, null, {
        default: _withCtx(() => [
          _createVNode(_component_CNavItem, {
            style: {"width":"100%"},
            class: "text-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AppContact)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_CSidebarFooter, null, {
        default: _withCtx(() => [
          _createVNode(_component_AppHeaderDropdownAccnt, null, {
            default: _withCtx(() => [
              _createVNode(_component_CDropdownToggle, {
                placement: "top-end",
                class: "py-0 nav-group-toggle",
                caret: false,
                href: "javascript:void(0);"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CAvatar, {
                    color: "secondary",
                    class: "ms-3 me-2"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.user.firstname[0]) + _toDisplayString($setup.user.lastname[0]), 1)
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString($setup.user.name), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible"]))
}