import { credentials } from '@/types/credentials'
import authService from '@/services/authService'
import apiClient from '@/services/apiClient'
import { MutationType, defineStore } from 'pinia'
import { userPreferencesStore } from './userPreferencesStore'
import router from '@/router'
import { createStore } from 'vuex'
import { jsonapiModule } from 'jsonapi-vuex'
import { createAccount } from '@/types/account'
import { createUser } from '@/types/user'
import { startAuthentication } from '@simplewebauthn/browser'

export const authStore = defineStore({
  id: 'auth',
  state: () => ({
    _initialized: false,
    user: createUser(),
    _account: createAccount(true),
    error: null,
    loading: false,
    upStore: userPreferencesStore(),
    reloadBody: '',
    verify401: false,
    api: createStore({
      modules: {
        jv: jsonapiModule(apiClient, {
          // recurseRelationships: true,
          preserveJson: true,
          cleanPatch: true,
        }),
      },
      strict: true,
    }),
  }),
  actions: {
    async initStore() {
      if (!this._initialized) {
        this._initialized = true
        if (!this.loggedIn) {
          await this.getAuthUser()

          if (this.loggedIn) {
            if (
              this.upStore._live_account_id != '' &&
              this.upStore._live_account_id != null
            ) {
              this.switchAccount(this.upStore._live_account_id, true)
            }
          }

          // subscribe to Pinia store
          this.upStore.$subscribe((mutation, state) => {
            if (
              mutation.type === MutationType.patchObject &&
              mutation.payload._live_account_id != undefined
            ) {
              this.switchAccount(state._live_account_id)
            }
            // if (Array.isArray(mutation.events)) {
            //   mutation.events.forEach((event) => {
            //     if (
            //       event.key == '_live_account_id' &&
            //       state._live_account_id != '' &&
            //       state._live_account_id != null
            //     ) {
            //       this.switchAccount(state._live_account_id)
            //     }
            //   })
            // } else if (
            //   mutation.events.key == '_live_account_id' &&
            //   state._live_account_id != '' &&
            //   state._live_account_id != null
            // ) {
            //   this.switchAccount(state._live_account_id)
            // }
          })
        }
      }
    },
    logout() {
      return authService
        .logout()
        .then(() => {
          this.user = createUser()
          this.router.push({ name: 'Login' })
        })
        .catch((error) => {
          this.error = error
        })
    },
    // eslint-disable-next-line
    async login(payload: credentials, i18n: any, method: string) {
      try {
        if (method == 'mfa-webauthn') {
          const options = await authService.getMfaAuthenticationOptions()
          const keyData = await startAuthentication(options.data.publicKey)
          await authService.verifyMfaAuthentication(keyData)
        } else if (method == 'mfa-totp') {
          await authService.verifyTotp({ code: payload.password })
        } else if (method == 'webauthn') {
          const options = await authService.getAuthenticationOptions()
          const keyData = await startAuthentication(options.data.publicKey)
          await authService.verifyAuthentication(keyData)
        } else {
          const response = await authService.login(payload)
          if (response.status == 200 && response.data.message == undefined) {
            return response.data
          }
        }
        await this.getAuthUser()

        if (this.loggedIn) {
          // eslint-disable-next-line
          if (this.user._jv?.id != undefined)
            await this.upStore.getPreferences(this.user._jv.id, i18n)

          if (this.upStore._live_account_id != this.upStore._account_id) {
            // actual switch is done through the upstore subscribe from the initStore
            this.upStore._live_account_id = this.upStore._account_id
          }

          this.router.push({ name: 'Dashboard' })
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getAuthUser() {
      try {
        await this.api.dispatch('jv/get', '/users/me').then((data) => {
          this.user = data
        })
      } catch (error) {
        console.log('store/auth: error!')
      }
    },
    async getUserAccount(account_id: string) {
      try {
        await this.api
          .dispatch('jv/get', [
            '/accounts/' + account_id,
            { params: { include: 'company,my-roles' } },
          ])
          .then(async (data) => {
            this._account = data
            if (Object.keys(data['my-roles']).length === 0) {
              // TODO: get superadmin role if available?
              await this.api
                .dispatch('jv/get', '/roles')
                .then((roleData) => {
                  Object.keys(roleData).forEach((userKey) => {
                    if (roleData[userKey].name == 'superadmin') {
                      data['my-roles'] = { userKey: roleData[userKey] }
                    }
                  })
                })
                .catch(() => {
                  console.log('failed to fetch roles')
                })
            }
          })
      } catch (error) {
        console.log('store/getUserAccount: error!')
        this._account = createAccount(true)
      }
    },
    async switchAccount(account_id: string, init = false) {
      try {
        // await apiClient.get('/accounts/' + account_id + '/select')
        await this.getUserAccount(account_id)
        if (!init) {
          if (
            router.currentRoute.value.meta.accountSwitchCompatible !=
              undefined &&
            router.currentRoute.value.meta.accountSwitchCompatible == true
          ) {
            this.reloadBody = Date().toString()
          } else {
            _redirectToAccountSwitchCompatibleRoute()
          }
        }
      } catch (error) {
        console.log('store/switchAccount: error!')
      }
    },
    // TODO: function to check if user has a matching between the given list and the role(s) within the current account
    // hasRoleInAccount(roles: Role[]) {
    //   let i = 0
    //   do {
    //     i++
    //   } while (
    //     i < props.roles.length &&
    //     // eslint-disable-next-line
    //     item.roles.filter((role) => props.roles![i].name == role).length ==
    //     0
    //   )

    //   if (i == props.roles.length) return ''
    // }
  },
  getters: {
    authUser: (state) => {
      return state.user
    },
    loggedIn: (state) => {
      return state.user._jv?.id != undefined
    },
    account: (state) => state._account,
    company: (state) => state._account.company,
    roles: (state) => state._account['my-roles'],
  },
})

function _redirectToAccountSwitchCompatibleRoute() {
  let found = false
  for (const route of router.currentRoute.value.matched.reverse()) {
    if (
      route.meta.accountSwitchCompatible != undefined &&
      route.meta.accountSwitchCompatible == true
    ) {
      router.push(route)
      found = true
    }
  }
  if (!found) {
    router.push({ name: 'Dashboard' })
  }
}
