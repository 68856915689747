<template>
  <CDropdown variant="nav-item" color="primary" direction="dropend">
    <slot>
      <CDropdownToggle
        placement="bottom-end"
        class="py-0"
        :caret="false"
        href="javascript:void(0);"
      >
        <!-- <CAvatar :src="avatar" size="md" /> -->
        <!-- <CIcon icon="cid-user-secret" size="xxl" /> -->
        <CIcon icon="cis-user" size="lg" />
      </CDropdownToggle>
    </slot>
    <CDropdownMenu>
      <!-- <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader>
      <CDropdownItem>
        <CIcon icon="cil-bell" /> Updates
        <CBadge color="info-gradient" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-envelope-open" /> Messages
        <CBadge color="success-gradient" class="ms-auto">{{
          itemsCount
        }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-task" /> Tasks
        <CBadge color="danger-gradient" class="ms-auto">{{
          itemsCount
        }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-comment-square" /> Comments
        <CBadge color="warning-gradient" class="ms-auto">{{
          itemsCount
        }}</CBadge>
      </CDropdownItem> -->
      <!-- <CDropdownHeader
        component="h6"
        :class="
          $store.state.theme === 'dark'
            ? 'fw-semibold py-2'
            : 'bg-light fw-semibold py-2'
        "
      >
        <div class="text-capitalize">
          {{ $t('setting', 2) }}
        </div>
      </CDropdownHeader> -->
      <router-link
        :to="{
          name: 'UserProfile',
        }"
        custom
        v-slot="{ href, navigate, isActive }"
      >
        <CDropdownItem :active="isActive" :href="href" @click="navigate">
          <div class="text-capitalize">
            <CIcon icon="cil-user" /> {{ $t('profile') }}
          </div>
        </CDropdownItem>
      </router-link>
      <!-- <router-link
        :to="{
          name: 'UserSettingsAccess',
        }"
        custom
        v-slot="{ href, navigate, isActive }"
      >
        <CDropdownItem :active="isActive" :href="href" @click="navigate">
          <div class="text-capitalize">
            <CIcon icon="cil-settings" /> {{ $t('setting', 2) }}
          </div>
        </CDropdownItem>
      </router-link> -->
      <!-- <CDropdownItem>
        <CIcon icon="cil-dollar" /> Payments
        <CBadge color="secondary" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-file" /> Projects
        <CBadge color="primary-gradient" class="ms-auto">{{
          itemsCount
        }}</CBadge>
      </CDropdownItem> -->
      <CDropdownDivider />
      <!-- <CDropdownItem>
        <CIcon icon="cil-shield-alt" /> Lock Account
      </CDropdownItem> -->
      <CDropdownItem @click="logout">
        <CIcon icon="cil-lock-locked" /> Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script lang="ts">
import avatar from './../assets/images/avatars/8.jpg'
import { authStore } from '@/store/authStore'

export default {
  name: 'AppHeaderDropdownAccnt',
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const auth = authStore()

    const logout = async () => {
      await auth.logout()
    }

    return {
      avatar: avatar,
      itemsCount: 42,
      logout,
    }
  },
}
</script>
