import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { authenticated } from '@/middleware'
// import { authStore } from '@/store/authStore'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: { requiresAuth: true },
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: { requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      // {
      //   path: '/contact',
      //   name: 'Contact',
      //   meta: { requiresAuth: true, accountSwitchCompatible: true },
      //   component: () => import('@/views/Contact.vue'),
      // },
      {
        path: '/user',
        name: 'Personal',
        meta: { requiresAuth: true, accountSwitchCompatible: true },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        // redirect: '/user/profile',
        redirect: { name: 'Profile' },
        children: [
          {
            path: 'profile',
            name: 'UserProfile',
            meta: {
              requiresAuth: true,
              accountSwitchCompatible: true,
              displayName: 'Profile',
            },
            component: () => import('@/views/user/Profile.vue'),
          },
          {
            path: 'overview',
            name: 'Accounts',
            meta: { requiresAuth: true },
            component: () => import('@/views/user/Accounts.vue'),
          },
          {
            path: 'settings',
            name: 'UserSettings',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: { name: 'UserSettingsAccess' },
            meta: { requiresAuth: true, displayName: 'Settings' },
            children: [
              {
                path: 'access',
                name: 'UserSettingsAccess',
                component: () => import('@/views/user/settings/Access.vue'),
                meta: { requiresAuth: true, displayName: 'Access' },
              },
              {
                path: 'api',
                name: 'UserSettingsApi',
                component: () => import('@/views/user/settings/Api.vue'),
                meta: { requiresAuth: true, displayName: 'Api' },
              },
              {
                path: 'ssh',
                name: 'UserSettingsSSH',
                component: () => import('@/views/user/settings/SSH.vue'),
                meta: { requiresAuth: true, displayName: 'SSH' },
              },
              {
                path: 'ip',
                name: 'UserSettingsIP',
                component: () => import('@/views/user/settings/IP.vue'),
                meta: { requiresAuth: true, displayName: 'IP' },
              },
            ],
          },
        ],
      },
      {
        path: '/account',
        name: 'Account',
        meta: {
          requiresAuth: true,
          accountSwitchCompatible: true,
        },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        // redirect: '/account/gebruikers',
        redirect: { name: 'Users' },
        children: [
          {
            path: 'contacts',
            name: 'Contacts',
            meta: {
              requiresAuth: true,
              accountSwitchCompatible: true,
            },
            component: () => import('@/views/account/Contacts.vue'),
          },
          {
            path: 'company',
            name: 'Company',
            meta: { requiresAuth: true },
            component: () => import('@/views/account/Company.vue'),
            // beforeEnter: (to, from) => {
            //   const store = authStore()
            //   if (
            //     store.company == undefined ||
            //     store.company._jv == undefined
            //   ) {
            //     return false
            //   }
            //   return true
            // },
          },
          {
            path: 'financial',
            name: 'Financial',
            meta: {
              requiresAuth: true,
              accountSwitchCompatible: true,
            },
            component: () => import('@/views/account/Finance.vue'),
          },
          {
            path: 'purchases',
            name: 'Purchases',
            meta: {
              requiresAuth: true,
              accountSwitchCompatible: true,
            },
            component: () => import('@/views/account/Purchases.vue'),
          },
          {
            path: 'users',
            name: 'Users',
            meta: {
              requiresAuth: true,
              accountSwitchCompatible: true,
            },
            component: () => import('@/views/account/Users.vue'),
          },
          {
            path: 'user/:userId',
            name: 'User',
            meta: {
              requiresAuth: true,
              accountSwitchCompatible: true,
            },
            component: () => import('@/views/account/User.vue'),
          },
        ],
      },
      {
        path: '/service',
        name: 'Service',
        meta: { requiresAuth: true },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: 'mail',
            name: 'ServiceMail',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            // redirect: '/service/mail/overview',
            redirect: { name: 'ServiceMailOverview' },
            meta: {
              requiresAuth: true,
              displayName: 'Mail',
              accountSwitchCompatible: true,
            },
            children: [
              {
                path: 'overview',
                name: 'ServiceMailOverview',
                component: () => import('@/views/service/mail/Overview.vue'),
                meta: {
                  requiresAuth: true,
                  displayName: 'Overview',
                  accountSwitchCompatible: true,
                },
              },
              {
                path: ':domainName/:domainId',
                name: 'ServiceMailDomain',
                // component: () => import('@/views/service/mail/Domain.vue'),
                component: {
                  render() {
                    return h(resolveComponent('router-view'))
                  },
                },
                meta: { requiresAuth: true, displayName: ':domainName' },
                redirect: { name: 'ServiceMailDomainOverview' },
                children: [
                  {
                    path: 'overview',
                    name: 'ServiceMailDomainOverview',
                    component: () => import('@/views/service/mail/Domain.vue'),
                    meta: {
                      requiresAuth: true,
                      displayName: 'Overview',
                      noLink: true,
                      accountSwitchCompatible: false,
                    },
                  },
                  {
                    path: 'forwards',
                    name: 'ServiceMailDomainForwards',
                    component: () =>
                      import('@/views/service/mail/Forwards.vue'),
                    meta: {
                      requiresAuth: true,
                      displayName: 'Forwards',
                      noLink: true,
                      accountSwitchCompatible: false,
                    },
                  },
                  {
                    path: 'settings',
                    name: 'ServiceMailDomainSettings',
                    component: () =>
                      import('@/views/service/mail/Settings.vue'),
                    meta: {
                      requiresAuth: true,
                      displayName: 'Settings',
                      noLink: true,
                      accountSwitchCompatible: false,
                    },
                  },
                  {
                    path: ':addressName/:addressId',
                    name: 'ServiceMailAddress',
                    // component: () => import('@/views/service/mail/Address.vue'),
                    component: {
                      render() {
                        return h(resolveComponent('router-view'))
                      },
                    },
                    meta: { requiresAuth: true, displayName: ':addressName' },
                    redirect: { name: 'ServiceMailAddressOverview' },
                    children: [
                      {
                        path: 'overview',
                        name: 'ServiceMailAddressOverview',
                        component: () =>
                          import('@/views/service/mail/Address.vue'),
                        meta: {
                          requiresAuth: true,
                          displayName: 'Overview',
                          noLink: true,
                          accountSwitchCompatible: false,
                        },
                      },
                      {
                        path: 'aliases',
                        name: 'ServiceMailAddressAliases',
                        component: () =>
                          import('@/views/service/mail/Aliases.vue'),
                        meta: {
                          requiresAuth: true,
                          displayName: 'Aliases',
                          noLink: true,
                          accountSwitchCompatible: false,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          // {
          //   path: 'hosting',
          //   name: 'ServiceHosting',
          //   component: {
          //     render() {
          //       return h(resolveComponent('router-view'))
          //     },
          //   },
          //   // redirect: '/service/mail/overview',
          //   redirect: { name: 'ServiceHostingOverview' },
          //   meta: {
          //     requiresAuth: true,
          //     displayName: 'Hosting',
          //     accountSwitchCompatible: true,
          //   },
          //   children: [
          //     {
          //       path: 'overview',
          //       name: 'ServiceHostingOverview',
          //       component: () => import('@/views/service/hosting/Overview.vue'),
          //       meta: {
          //         requiresAuth: true,
          //         displayName: 'Overview',
          //         accountSwitchCompatible: true,
          //       },
          //     },
          //     {
          //       path: ':domain/:hostingPackageId',
          //       name: 'ServiceHostingPackage',
          //       component: () => import('@/views/service/hosting/Package.vue'),
          //       meta: {
          //         requiresAuth: true,
          //         displayName: ':domain',
          //         noLink: true,
          //         accountSwitchCompatible: false,
          //       },
          //     },
          //   ],
          // },
          {
            path: 'dns',
            name: 'ServiceDns',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: { name: 'ServiceDnsZones' },
            meta: {
              requiresAuth: true,
              displayName: 'Dns',
              accountSwitchCompatible: true,
              roles: ['superadmin'],
            },
            children: [
              {
                path: 'zones',
                name: 'ServiceDnsZones',
                component: () => import('@/views/service/dns/Zones.vue'),
                meta: {
                  requiresAuth: true,
                  displayName: 'Zones',
                  accountSwitchCompatible: true,
                },
              },
              {
                path: ':domainName/:zoneId',
                name: 'ServiceDnsZone',
                // component: () => import('@/views/service/mail/Domain.vue'),
                component: {
                  render() {
                    return h(resolveComponent('router-view'))
                  },
                },
                meta: { requiresAuth: true, displayName: ':domainName' },
                redirect: { name: 'ServiceDnsZoneOverview' },
                children: [
                  {
                    path: 'overview',
                    name: 'ServiceDnsZoneOverview',
                    component: () => import('@/views/service/dns/Zone.vue'),
                    meta: {
                      requiresAuth: true,
                      displayName: 'overview',
                      noLink: true,
                      accountSwitchCompatible: false,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/tools',
        name: 'Tools',
        meta: { requiresAuth: true },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: 'migration',
            name: 'ToolsMigration',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: { name: 'ToolsMigrationAssistant' },
            meta: { requiresAuth: true },
            children: [
              {
                path: 'assistant',
                name: 'ToolsMigrationAssistant',
                component: () =>
                  import('@/views/tools/migration/Assistant.vue'),
                meta: { requiresAuth: true },
              },
              {
                path: 'domain',
                name: 'ToolsMigrationDomain',
                component: () => import('@/views/tools/migration/Domain.vue'),
                meta: { requiresAuth: true },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/auth/login',
    name: 'Login',
    meta: { requiresAuth: false },
    component: () => import('@/views/pages/Login.vue'),
  },
  {
    path: '/auth/password/forgot',
    name: 'Forgot',
    meta: { requiresAuth: false },
    component: () => import('@/views/pages/Forgot.vue'),
  },
  {
    path: '/auth/password/reset/:token',
    name: 'Reset',
    meta: { requiresAuth: false },
    component: () => import('@/views/pages/Reset.vue'),
  },
  {
    path: '/auth/invite/accept/:token',
    name: 'InviteAccept',
    meta: { requiresAuth: false },
    component: () => import('@/views/pages/Reset.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/pages/NotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})
router.beforeEach(authenticated)

export default router
