import { defineStore } from 'pinia'
import apiClient from '@/services/apiClient'

export const userPreferencesStore = defineStore({
  id: 'userPreferencesStore',
  state: () => ({
    _id: '',
    _account_id: '',
    _live_account_id: '',
    _locale: 'nl_NL',
  }),
  getters: {
    account_id: (state) => state._account_id,
    locale: (state) => state._locale.replace(/_/g, '-'),
    live_account_id: (state) => state._live_account_id,
  },
  persist: true,
  actions: {
    setLocale(locale: string) {
      this._locale = locale.replace(/-/g, '_')
    },
    setAccountId(account_id: string) {
      this._account_id = account_id
    },
    // eslint-disable-next-line
    async getPreferences(user_id: string, i18n: any) {
      try {
        const response = await apiClient.get('/user-settings/' + user_id)
        this._id = response.data.data.id
        this._account_id = response.data.data.attributes.account_id
        if (response.data.data.attributes.locale != null) {
          this._locale = response.data.data.attributes.locale
          i18n.locale.value = this.locale
        } else {
          this.savePreferences(user_id)
        }

        const responseAccount = await apiClient.get(
          '/accounts?page[size]=1&page[number]=1',
        )
        if (
          (this._account_id == null || this._account_id == '') &&
          responseAccount.data.meta.page.total == 1
        ) {
          this._account_id = responseAccount.data.data[0].id
          this.savePreferences(user_id)
        }

        if (this._live_account_id == '') {
          this._live_account_id = this._account_id
        }
      } catch (error) {
        console.log('upStore/getPreferences: error!')
      }
    },
    async savePreferences(user_id: string) {
      try {
        await apiClient.patch('/user-settings/' + user_id, {
          data: {
            type: 'user-settings',
            id: this._id,
            attributes: {
              account_id: this._account_id,
              locale: this._locale,
            },
          },
        })
        return true
      } catch (error) {
        console.log('upStore/save: error!')
        return false
      }
    },
  },
})
