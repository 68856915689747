<template>
  <CButton
    color="danger"
    variant="outline"
    square
    size="sm"
    class="me-2"
    @click="modalOpen()"
  >
    <CIcon customClassName="icon text-primary" icon="cil-trash" />
  </CButton>
  <CModal
    alignment="center"
    :visible="deleteModal"
    @close="
      () => {
        deleteModal = false
      }
    "
    size="sm"
  >
    <!-- <CModalHeader>
      <CModalTitle class="text-capitalize">{{ $t('delete') }}</CModalTitle>
    </CModalHeader> -->
    <CModalBody> <slot></slot> </CModalBody>
    <CModalFooter>
      <CButton
        color="secondary"
        class="text-capitalize"
        @click="
          () => {
            deleteModal = false
          }
        "
      >
        {{ $t('cancel') }}
      </CButton>
      <CButton
        id="buttenDelete"
        color="danger"
        class="text-capitalize"
        @click="apiDelete()"
        @keyup.enter="apiDelete()"
        >{{ $t('delete') }}</CButton
      >
    </CModalFooter>
  </CModal>
</template>

<script>
// import { useStore } from 'vuex'
// import { useI18n } from 'vue-i18n'
import { ref, nextTick } from 'vue'

export default {
  name: 'ButtonDelete',
  props: {
    id: String,
    apiUrl: String,
  },
  emits: ['delete'],
  setup(props, { emit }) {
    // const store = useStore()
    // const i18n = useI18n()

    const deleteModal = ref(false)

    const modalOpen = () => {
      deleteModal.value = true
      nextTick(() => {
        document.getElementById('buttenDelete').focus()
      })
    }
    const apiDelete = () => {
      // store.dispatch('jv/delete', [props.apiUrl + props.id]).then((data) => {
      //   if (data == props.apiUrl + props.id) {
      deleteModal.value = false
      emit('delete')
      //   }
      // })
    }

    return {
      deleteModal,
      modalOpen,
      apiDelete,
    }
  },
}
</script>
