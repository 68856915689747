export interface user {
  _jv?: {
    type: string
    id?: string
  }
  account_id: string
  name: string
  phone: string
  email: string
  firstname: string
  lastname: string
  active: boolean
  created_at: string
  updated_at: string
}

export function createUser(): user {
  return {
    _jv: {
      type: 'users',
    },
    account_id: '',
    name: '',
    phone: '',
    email: '',
    firstname: '',
    lastname: '',
    active: false,
    created_at: '',
    updated_at: '',
  }
}
