import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CBreadcrumbItem = _resolveComponent("CBreadcrumbItem")!
  const _component_CBreadcrumb = _resolveComponent("CBreadcrumb")!
  const _component_CIcon = _resolveComponent("CIcon")!
  const _component_CHeaderToggler = _resolveComponent("CHeaderToggler")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CBreadcrumb, { class: "d-md-down-none me-auto mb-0" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.breadcrumbs, (item) => {
          return (_openBlock(), _createBlock(_component_CBreadcrumbItem, {
            href: item.active ? '' : item.path,
            active: item.active,
            key: item
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(item.name)), 1)
            ]),
            _: 2
          }, 1032, ["href", "active"]))
        }), 128))
      ]),
      _: 1
    }),
    ($setup.sidebarVisible)
      ? (_openBlock(), _createBlock(_component_CHeaderToggler, {
          key: 0,
          class: "px-md-0 me-md-3"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CIcon, {
              icon: "cil-applications-settings",
              size: "lg",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.commit('toggleAside')))
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}