import { company, createCompany } from './company'
import { role } from './role'

export interface account {
  _jv?: {
    type: string
    id?: string
  }
  name: string
  display_name: string
  description: string
  created_at: string
  updated_at: string
  'my-roles': { [key: string]: role }
  company?: company
}

export function createAccount(withCompany = false): account {
  return {
    _jv: {
      type: 'accounts',
    },
    name: '',
    display_name: '',
    description: '',
    created_at: '',
    updated_at: '',
    'my-roles': {},
    company: withCompany ? createCompany() : undefined,
  }
}
