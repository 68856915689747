<template>
  <CModal
    alignment="center"
    :visible="accountSelectModal"
    :keyboard="false"
    backdrop="static"
  >
    <CModalHeader :closeButton="false">
      <CModalTitle><CIcon icon="cil-people" /> Account select </CModalTitle>
      <CButton
        color="secondary"
        class="text-capitalize float-end"
        @click="logout"
      >
        {{ $t('logout') }}
      </CButton>
    </CModalHeader>
    <CModalBody>
      <GenericTable
        apiUrl="/accounts?include=company"
        :columns="columns"
        cleaner
      >
        <template #type="{ item }">
          <td>
            <CTooltip
              :content="
                item.company.name == undefined ? $t('private') : $t('business')
              "
              placement="top"
            >
              <template #toggler="{ on }">
                <CIcon
                  :icon="
                    item.company.name == undefined
                      ? 'cil-user'
                      : 'cil-building-business'
                  "
                  v-on="on"
                />
              </template>
            </CTooltip>
          </td>
        </template>
        <template #active="{ item }">
          <td>
            <CBadge color="success" v-if="item._jv.id == activeAccountId">
              Active
            </CBadge>
          </td>
        </template>
        <template #rowModifiers="{ item }">
          <CButton
            :color="item._jv.id == activeAccountId ? 'success' : 'primary'"
            variant="outline"
            square
            size="sm"
            @click="switchAccount(item._jv.id)"
            :disabled="item._jv.id == activeAccountId"
          >
            <CIcon icon="cid-loop-square" />
          </CButton>
        </template>
      </GenericTable>
    </CModalBody>
  </CModal>
</template>

<script>
import { ref } from '@vue/reactivity'
import { authStore } from '@/store/authStore'
import GenericTable from '@/components/generic/Table.vue'
import { userPreferencesStore } from '@/store/userPreferencesStore'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

export default {
  name: 'AccountSelect',
  components: {
    GenericTable,
  },
  setup() {
    const accountSelectModal = ref(false)

    const upStore = userPreferencesStore()
    const i18n = useI18n()

    setTimeout(
      () =>
        (accountSelectModal.value =
          upStore._live_account_id == '' || upStore._live_account_id == null),
      1,
    )

    const columns = [
      {
        key: 'display_name',
        label: i18n.t('name'),
        _props: { class: 'text-capitalize' },
      },
      // {
      //   key: 'description',
      //   label: i18n.t('description'),
      //   _props: { class: 'text-capitalize' },
      //   filter: false,
      //   sorter: false,
      // },
      {
        key: 'type',
        label: i18n.t('type'),
        _props: { class: 'text-capitalize' },
        filter: false,
        sorter: false,
      },
    ]

    const getBadge = (status) => {
      return status ? 'success' : 'secondary'
    }

    const switchAccount = (account_id) => {
      upStore.$patch({ _live_account_id: account_id })
      accountSelectModal.value = false
    }

    const logout = async () => {
      const aStore = authStore()
      await aStore.logout()
    }

    return {
      accountSelectModal,
      columns,
      getBadge,
      switchAccount,
      activeAccountId: computed(() => upStore._live_account_id),
      logout,
    }
  },
}
</script>
