export interface company {
  _jv?: {
    type: string
    id?: string
  }
  account_id: string
  name: string
  kvknr: string
  phone: string
  email: string
  created_at: string
  updated_at: string
}

export function createCompany(): company {
  return {
    _jv: {
      type: 'companies',
    },
    account_id: '',
    name: '',
    kvknr: '',
    phone: '',
    email: '',
    created_at: '',
    updated_at: '',
  }
}
