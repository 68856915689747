import { authStore } from '@/store/authStore'
import { role } from '@/types/role'

const guestAuthRoutes = ['Login', 'Reset', 'Forgot']

// eslint-disable-next-line
export const authenticated = async (to: any) => {
  // Fetch user if needed
  const auth = authStore()
  await auth.initStore()

  // Check if the route requires authentication
  if (to.meta.requiresAuth && !auth.loggedIn) {
    return { path: '/auth/login', query: { redirect: to.fullPath } }
  }

  // User logged in and we're currently on a guest route
  if (auth.loggedIn && guestAuthRoutes.includes(to.name)) {
    return { path: '/dashboard' }
  }

  // Check if the user has the required role
  if (to.meta.roles != undefined) {
    let found = false
    to.meta.roles.forEach((role: string) => {
      Object.values(auth.roles).forEach((roleObj: role) => {
        if (roleObj.name == role) {
          found = true
        }
      })
    })
    if (!found) return { path: '/dashboard' }
  }
}
