<template>
  <CSidebar
    position="fixed"
    selfHiding="md"
    :unfoldable="false"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <CIcon
        customClassName="sidebar-brand-full"
        :icon="logoNegative"
        :height="35"
        :width="180"
      />
      <CIcon
        customClassName="sidebar-brand-narrow"
        :icon="sygnetNegative"
        :height="35"
      />
    </CSidebarBrand>
    <AppSidebarNav
      :accountName="account.display_name"
      :roles="roles"
      :key="account.display_name"
    />
    <CNav>
      <CNavItem style="width: 100%" class="text-center">
        <AppContact />
      </CNavItem>
    </CNav>
    <CSidebarFooter>
      <AppHeaderDropdownAccnt>
        <CDropdownToggle
          placement="top-end"
          class="py-0 nav-group-toggle"
          :caret="false"
          href="javascript:void(0);"
        >
          <CAvatar color="secondary" class="ms-3 me-2">
            {{ user.firstname[0] }}{{ user.lastname[0] }}
          </CAvatar>
          {{ user.name }}
        </CDropdownToggle>
      </AppHeaderDropdownAccnt>
    </CSidebarFooter>
  </CSidebar>
</template>

<script lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-cacholong-negative'
import { sygnetNegative } from '@/assets/brand/sygnet-cacholong-negative'
import { logo } from '@/assets/brand/logo-cacholong'
import { authStore } from '@/store/authStore'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt.vue'
import AppContact from '@/components/Contact.vue'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
    AppHeaderDropdownAccnt,
    AppContact,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const store = useStore()
    const aStore = authStore()
    const roles = ref([])

    store
      .dispatch('jv/get', '/roles')
      // [
      //   '/mail-addresses/' + route.params.addressId,
      //   { params: { include: 'mail-domain.domain' } },
      // ])
      .then((data) => {
        roles.value = Object.values(data)
      })
      .catch(() => {
        console.log('failed to fetch roles')
      })

    onMounted(() => {
      window.addEventListener('resize', toggleSidebar)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', toggleSidebar)
    })

    const toggleSidebar = () => {
      if (
        !store.state.sidebarVisible &&
        document.documentElement.clientWidth > 750
      ) {
        store.commit({ type: 'updateSidebarVisible', value: true })
      }
    }

    if (aStore.user.firstname == null) {
      aStore.user.firstname = ''
    }
    if (aStore.user.lastname == null) {
      aStore.user.lastname = ''
    }

    return {
      logoNegative,
      logo,
      sygnetNegative,
      roles,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
      account: computed(() => {
        return aStore.account
      }),
      user: computed(() => {
        return aStore.user
      }),
    }
  },
}
</script>
