import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent" }
const _hoisted_2 = { class: "body flex-grow-1 px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToast = _resolveComponent("AppToast")!
  const _component_AccountSelect = _resolveComponent("AccountSelect")!
  const _component_AppSidebar = _resolveComponent("AppSidebar")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CContainer = _resolveComponent("CContainer")!
  const _component_AppAside = _resolveComponent("AppAside")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppToast),
    _createVNode(_component_AccountSelect),
    _createVNode(_component_AppSidebar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AppHeader),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createBlock(_component_CContainer, {
          lg: "",
          key: $setup.reloadBody
        }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }))
      ])
    ]),
    _createVNode(_component_AppAside)
  ], 64))
}