import { createApp, markRaw } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import http from '@/services/apiClient'
import { AxiosKey } from '@/symbols'

import CoreuiVue from '@coreui/vue-pro'
import { CIcon } from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

import { ToastPlugin } from './plugins/toast'
import { PreferencesPlugin } from './plugins/preferences'

import { userPreferencesStore } from '@/store/userPreferencesStore'

import { createI18n } from 'vue-i18n'
import enUS from './locales/en.json'
import nlNL from './locales/nl.json'

// add the router to pinia as a plugin for easy access
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

const app = createApp(App)
app.use(store)
app.use(pinia)
app.use(router)
app.use(CoreuiVue, [])
app.use(ToastPlugin)
app.use(PreferencesPlugin)
app.provide(AxiosKey, http)
app.provide('icons', icons)
app.component('CIcon', CIcon)

const upStore = userPreferencesStore()

type MessageSchema = typeof enUS
const i18n = createI18n<[MessageSchema], 'en-US' | 'nl-NL'>({
  legacy: false,
  locale: upStore.locale,
  fallbackLocale: 'en-US',
  messages: {
    'en-US': enUS,
    'nl-NL': nlNL,
  },
  numberFormats: {
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
    'nl-NL': {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
})

app.use(i18n)

app.mount('#app')
