<template>
  <CContainer>
    <CButton
      variant="ghost"
      color="link"
      class="active mx-2 my-1"
      shape="rounded-pill"
      @click="
        () => {
          contactModal = true
        }
      "
    >
      <CIcon icon="cil-phone" size="lg" />
      Contact
    </CButton>
    <CModal
      alignment="center"
      :visible="contactModal"
      @close="contactModal = false"
      class="text-start"
    >
      <CModalHeader style="color: rgba(44, 56, 74, 0.95)">
        <CModalTitle class="text-capitalize">
          {{ $t('contact') }}
        </CModalTitle>
      </CModalHeader>
      <CModalBody style="color: rgba(44, 56, 74, 0.95)">
        <CRow>
          <CCol xs="5" class="fw-bolder"
            ><CIcon icon="cil-phone" /> {{ $t('phone') }}</CCol
          >
        </CRow>
        <CRow>
          <CCol xs="5">{{ $t('office') }}</CCol>
          <CCol xs="6"
            ><a href="tel:+31 85 877 1846">+31 (0) 85 877 1846</a></CCol
          >
        </CRow>
        <CRow>
          <CCol xs="5">{{ $t('emergency') }}</CCol>
          <CCol xs="6"
            ><a href="tel:+31 85 876 9058">+31 (0) 85 876 9058</a></CCol
          >
        </CRow>
        <hr />
        <CRow>
          <CCol xs="5" class="fw-bolder"
            ><CIcon icon="cil-mail" /> {{ $t('email') }}</CCol
          >
        </CRow>
        <CRow>
          <CCol xs="5">{{ $t('support') }}</CCol>
          <CCol xs="6" class="text-lowercase"
            ><a href="mailto: support@cacholong.nl"
              >support@cacholong.nl</a
            ></CCol
          >
        </CRow>
        <CRow>
          <CCol xs="5">{{ $t('administration') }}</CCol>
          <CCol xs="6" class="text-lowercase"
            ><a href="mailto: administratie@cacholong.nl"
              >administratie@cacholong.nl</a
            ></CCol
          >
        </CRow>
        <hr />
        <CRow>
          <CCol xs="5" class="fw-bolder"
            ><CIcon icon="cil-clock" /> {{ $t('business_hours') }}</CCol
          >
        </CRow>
        <CRow>
          <CCol xs="5">{{ $t('office') }}</CCol>
          <CCol xs="6">ma-vr 09:00-16:30</CCol>
        </CRow>
        <CRow>
          <CCol xs="5">{{ $t('emergency') }}</CCol>
          <CCol xs="6">24/7</CCol>
        </CRow></CModalBody
      >
    </CModal>
  </CContainer>
</template>

<script>
import { ref } from '@vue/reactivity'

export default {
  name: 'AppContact',
  setup() {
    const contactModal = ref(false)

    return {
      contactModal,
    }
  },
}
</script>
