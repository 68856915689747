<template>
  <CBreadcrumb class="d-md-down-none me-auto mb-0">
    <CBreadcrumbItem
      v-for="item in breadcrumbs"
      :href="item.active ? '' : item.path"
      :active="item.active"
      :key="item"
    >
      {{ $t(item.name) }}
    </CBreadcrumbItem>
  </CBreadcrumb>

  <CHeaderToggler class="px-md-0 me-md-3" v-if="sidebarVisible">
    <CIcon
      icon="cil-applications-settings"
      size="lg"
      @click="$store.commit('toggleAside')"
    />
  </CHeaderToggler>
</template>

<script lang="ts">
import { onMounted, ref, computed } from 'vue'
import { RouteLocationMatched } from 'vue-router'
import router from '@/router'
import { useStore } from 'vuex'

export default {
  name: 'AppBreadcrumb',
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const store = useStore()
    const breadcrumbs = ref()

    const getBreadcrumbs = () => {
      return router.currentRoute.value.matched.map(
        (route: RouteLocationMatched) => {
          return {
            // active: route.path === router.currentRoute.value.fullPath,
            active:
              route.path === router.currentRoute.value.fullPath ||
              route.meta.noLink,
            //name: route.name,
            name: parseName(route),
            // path: `${router.options.history.base}${route.path}`,
            path: router.resolve({
              name: route.name,
              params: router.currentRoute.value.params,
            }).href,
          }
        },
      )
    }

    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    onMounted(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    const parseName = (route: RouteLocationMatched) => {
      let name
      if (typeof route.meta.displayName !== 'undefined') {
        if (String(route.meta.displayName).startsWith(':')) {
          name =
            router.currentRoute.value.params[
              String(route.meta.displayName).substring(1)
            ]
          name = name[0].toUpperCase() + name.slice(1)

          if (name.length > 20) name = name.substring(1, 15) + '...'
        } else {
          name = route.meta.displayName
        }
      } else {
        name = route.name
      }
      return name
    }

    return {
      breadcrumbs,
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
