import axios, { AxiosInstance } from 'axios'
import { authStore } from '@/store/authStore'

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL + '/api/v1',
  headers: {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
  },
  withCredentials: true,
})

// apiClient.interceptors.request.use((config) => {
//   // Old method
//   // // Remove query string from type. Abused for automaticly generating bug reports
//   // try {
//   //   config.data.data.type = config.data.data.type.replace(/\?.*/, '')
//   //   // eslint-disable-next-line
//   // } catch { }

//   // New method: Add issue param to request for auto generating an issue report
//   if (config.method != undefined && config.method != 'get') {
//     if (typeof config.params === 'object') {
//       config.params.issue = ''
//     } else {
//       config.params = { issue: '' }
//     }
//   }
//   console.log('request', config)
//   return config
// })

apiClient.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const auth = authStore()
    if (
      auth.router.currentRoute.value.meta.requiresAuth &&
      !auth.verify401 &&
      error.response &&
      error.response.status == 401
    ) {
      // test if authorized. Else send to login page
      auth.verify401 = true
      try {
        await apiClient.get('/users/me?fields[users]=id')
        auth.verify401 = false
      } catch (error2) {
        auth.verify401 = false
        auth.logout()
      }
    }
    return Promise.reject(error)
  },
)

export default apiClient
