export const logo = [
  '382 47',
  `
  <title>cacholong logo</title>
  <style type="text/css">
	  .st0{fill:#140E41;}
  	.st1{fill:#43B4B1;}
  </style>
  <g>
    <path class="st0" d="M36.3,6.4c3.4,2.7,5.6,6.5,6.6,11.1H32.5c-0.8-1.9-2-3.4-3.6-4.5s-3.5-1.6-5.7-1.6c-2.9,0-5.2,1.1-7,3.2
      c-1.8,2.1-2.7,5-2.7,8.5c0,3.6,0.9,6.4,2.7,8.5c1.8,2.1,4.1,3.2,7,3.2c2.2,0,4.1-0.5,5.7-1.6c1.6-1.1,2.8-2.6,3.6-4.4H43
      c-1,4.6-3.2,8.3-6.6,11.1c-3.4,2.8-7.7,4.1-13,4.1c-4,0-7.5-0.9-10.5-2.6c-3-1.8-5.3-4.2-7-7.4s-2.5-6.8-2.5-10.9s0.8-7.7,2.5-10.9
      c1.6-3.2,4-5.6,7-7.4s6.5-2.6,10.5-2.6C28.6,2.2,32.9,3.6,36.3,6.4z M74,36.2H58.6L56,43.7H45.6L60.6,3H72l15,40.7H76.5L74,36.2z
      M71.4,28.6l-5.1-15.1l-5.2,15.1H71.4z M122.6,6.4c3.4,2.7,5.6,6.5,6.6,11.1h-10.5c-0.8-1.9-2-3.4-3.6-4.5s-3.5-1.6-5.7-1.6
      c-2.9,0-5.2,1.1-7,3.2s-2.7,5-2.7,8.5c0,3.6,0.9,6.4,2.7,8.5c1.8,2.1,4.1,3.2,7,3.2c2.2,0,4.1-0.5,5.7-1.6c1.6-1.1,2.8-2.6,3.6-4.4
      h10.5c-1,4.6-3.2,8.3-6.6,11.1c-3.4,2.8-7.7,4.1-13,4.1c-4,0-7.5-0.9-10.5-2.6c-3-1.8-5.3-4.2-7-7.4c-1.6-3.2-2.5-6.8-2.5-10.9
      s0.8-7.7,2.5-10.9c1.6-3.2,4-5.6,7-7.4s6.5-2.6,10.5-2.6C114.9,2.2,119.2,3.6,122.6,6.4z M170.3,2.6v41h-10V26.5h-15.6v17.1h-9.9
      v-41h9.9v15.9h15.6V2.6H170.3z M207.2,4.7c3.2,1.8,5.6,4.3,7.5,7.5c1.8,3.2,2.7,6.8,2.7,10.8s-0.9,7.6-2.8,10.9
      c-1.8,3.2-4.3,5.7-7.5,7.5c-3.2,1.8-6.6,2.7-10.5,2.7s-7.3-0.9-10.5-2.7s-5.6-4.3-7.5-7.5c-1.8-3.2-2.8-6.8-2.8-10.9
      c0-4,0.9-7.6,2.8-10.8c1.8-3.2,4.3-5.7,7.5-7.5S192.7,2,196.6,2S204,2.9,207.2,4.7z M188.9,14.4c-1.9,2.1-2.9,5-2.9,8.6
      s1,6.5,2.9,8.6s4.5,3.2,7.7,3.2s5.8-1.1,7.7-3.2s2.9-5,2.9-8.6s-1-6.5-2.9-8.6s-4.5-3.2-7.7-3.2S190.8,12.3,188.9,14.4z
      M232.9,36.1h13.2v7.6h-23.2v-41h9.9v33.4L232.9,36.1z M280.7,4.7c3.2,1.8,5.6,4.3,7.5,7.5c1.8,3.2,2.7,6.8,2.7,10.8
      s-0.9,7.6-2.8,10.9c-1.8,3.2-4.3,5.7-7.5,7.5c-3.2,1.8-6.6,2.7-10.5,2.7s-7.3-0.9-10.5-2.7c-3.2-1.8-5.6-4.3-7.5-7.5
      c-1.8-3.2-2.8-6.8-2.8-10.9c0-4,0.9-7.6,2.8-10.8c1.8-3.2,4.3-5.7,7.5-7.5c3.2-1.8,6.8-2.7,10.6-2.7C274.1,2,277.6,2.9,280.7,4.7z
      M262.4,14.4c-1.9,2.1-2.9,5-2.9,8.6s1,6.5,2.9,8.6s4.5,3.2,7.7,3.2s5.8-1.1,7.7-3.2c1.9-2.1,2.9-5,2.9-8.6s-1-6.5-2.9-8.6
      s-4.5-3.2-7.7-3.2S264.4,12.3,262.4,14.4z M332.9,43.7H323l-16.6-25.2v25.2h-9.9v-41h9.9L323,28.1V2.6h9.9
      C332.9,2.6,332.9,43.7,332.9,43.7z M371.7,6c3.3,2.5,5.4,5.9,6.2,10.2h-10.5c-0.7-1.5-1.8-2.6-3.2-3.5c-1.4-0.9-3.2-1.3-5.2-1.3
      c-3.1,0-5.6,1.1-7.5,3.2s-2.8,5-2.8,8.6c0,3.9,1,6.9,3,9c2,2.1,4.8,3.1,8.5,3.1c2.4,0,4.4-0.6,6.2-1.9c1.8-1.3,3-3.1,3.8-5.5h-12.5
      v-7.1h20.7v9.8c-0.8,2.4-2,4.6-3.7,6.7c-1.7,2.1-3.9,3.7-6.5,5s-5.7,1.9-9.1,1.9c-4.1,0-7.8-0.9-10.9-2.6c-3.1-1.8-5.5-4.2-7.2-7.4
      s-2.5-6.8-2.5-10.9s0.8-7.7,2.5-10.9s4.1-5.6,7.1-7.4c3.1-1.8,6.7-2.6,10.8-2.6C364.1,2.2,368.4,3.5,371.7,6L371.7,6z"/>
  </g>
  <path class="st1" d="M196.7,9.6c-7.4,0-13.4,6-13.4,13.4s6,13.4,13.4,13.4s13.4-6,13.4-13.4S204.1,9.6,196.7,9.6z M196.7,29.3
    c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C203,26.4,200.2,29.3,196.7,29.3z"/>
  `,
]
