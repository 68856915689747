import axios, { AxiosInstance } from 'axios'
import { credentials } from '@/types/credentials'

export const authClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

// Intercept unauthorized or 419 (expired xsrf token)
authClient.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    if (error.response && [401, 419].includes(error.response.status)) {
      console.log('401 or 419 response...')
    }
    return Promise.reject(error)
  },
)

export default {
  async login(payload: credentials) {
    await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/auth/login', payload)
  },
  async logout() {
    return authClient.post('/auth/logout')
  },
  async forgotPassword(payload: credentials) {
    await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/auth/password/email', payload)
  },
  async resetPassword(payload: credentials) {
    await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/auth/password/reset', payload)
  },
  async acceptInvite(payload: credentials) {
    await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/auth/invite/accept', payload)
  },
  async getRegistrationOptions(email: string) {
    // await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/auth/mfa/webauthn/register/options', {
      email: email,
    })
  },
  async verifyRegistration(body: object) {
    // await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/auth/mfa/webauthn/register', body)
  },
  async getAuthenticationOptions() {
    await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/auth/login/options', {})
  },
  async verifyAuthentication(body: object) {
    // await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/auth/login', body)
  },
  async getMfaAuthenticationOptions() {
    await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/auth/mfa/webauthn/confirm/options', {})
  },
  async verifyMfaAuthentication(body: object) {
    await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/auth/mfa/webauthn/confirm', body)
  },
  async verifyTotp(body: object) {
    await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/auth/mfa/totp/confirm', body)
  },
}
