export const sygnetNegative = [
  '48 48',
  `
  <title>cacholong logo</title>
  <style type="text/css">
    .st0{fill:#FFFFFF;}
    .st1{fill:#43B4B1;}
  </style>
  <g>
    <path class="st0" d="M34.1,4.7c3.2,1.8,5.6,4.3,7.5,7.5c1.8,3.2,2.7,6.8,2.7,10.8s-0.9,7.6-2.8,10.9c-1.8,3.2-4.3,5.7-7.5,7.5
      s-6.6,2.7-10.5,2.7s-7.3-0.9-10.5-2.7s-5.6-4.3-7.5-7.5C3.7,30.7,2.7,27.1,2.7,23c0-4,0.9-7.6,2.8-10.8C7.3,9,9.8,6.5,13,4.7
      S19.6,2,23.5,2S30.9,2.9,34.1,4.7z M15.8,14.4c-1.9,2.1-2.9,5-2.9,8.6s1,6.5,2.9,8.6s4.5,3.2,7.7,3.2s5.8-1.1,7.7-3.2
      s2.9-5,2.9-8.6s-1-6.5-2.9-8.6s-4.5-3.2-7.7-3.2S17.7,12.3,15.8,14.4z"/>
  </g>
  <path class="st1" d="M23.6,9.6c-7.4,0-13.4,6-13.4,13.4s6,13.4,13.4,13.4S37,30.4,37,23S31,9.6,23.6,9.6z M23.6,29.3
    c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3C29.9,26.4,27.1,29.3,23.6,29.3z"/>
`,
]
