import { createStore } from 'vuex'
import apiClient from '@/services/apiClient'
import { jsonapiModule } from 'jsonapi-vuex'

export default createStore({
  modules: {
    jv: jsonapiModule(apiClient, {
      // recurseRelationships: true,
      preserveJson: true,
      cleanPatch: true,
      cleanPatchProps: ['meta'],
    }),
  },
  strict: true,
  state: {
    asideVisible: false,
    sidebarVisible: true,
    sidebarUnfoldable: false,
    theme: 'default',
  },
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  },
  actions: {},
})
