<template>
  <div>
    <CRow>
      <CCol>
        <CButton
          variant="ghost"
          @click="resetFilters"
          v-if="cleaner"
          :disabled="filter_reset_disabled"
        >
          <CIcon icon="cil-filter-x" size="lg" />
        </CButton>
      </CCol>
    </CRow>
    <CSmartTable
      :tableProps="{
        striped: true,
        hover: true,
      }"
      :selectable="selectable"
      footer
      header
      :key="per_page + reset"
      :items="data"
      :columns="ccolumns"
      :columnFilter="{
        external: true,
      }"
      :columnFilterValue="filters"
      :itemsPerPage="per_page"
      :columnSorter="{
        external: true,
      }"
      :sorterValue="sort"
      :loading="loading"
      @columnFilterChange="debounce(() => columnFilterChange($event))"
      @sorterChange="(sort) => sorterChange(sort)"
    >
      <template v-for="column in ccolumns" #[column.key]="{ item }">
        <td v-if="item[column.key] == null" :key="column.key"></td>
        <td
          v-else-if="column.type != undefined && column.type == 'date'"
          :key="column.key"
        >
          {{ parseDate(item[column.key]) }}
        </td>
        <td v-else :key="column.key">{{ item[column.key] }}</td>
      </template>
      <template v-for="(_, slot) in $slots" v-slot:[slot]="slotProps">
        <slot :name="slot" v-bind="slotProps" v-if="slot != 'actions'"></slot>
      </template>
      <template #actions="{ item }" v-if="rowModifiers != {}"
        ><td>
          <CButton
            color="primary"
            variant="outline"
            square
            size="sm"
            class="me-2"
            @click="rowModifiers.open.click(item)"
            v-if="rowModifiers.hasOwnProperty('open')"
          >
            <CIcon icon="cil-note" />
          </CButton>
          <CButton
            color="warning"
            variant="outline"
            square
            size="sm"
            class="me-2"
            @click="rowModifiers.edit.click(item)"
            v-if="rowModifiers.hasOwnProperty('edit')"
          >
            <CIcon customClassName="icon text-primary" icon="cil-pencil" />
          </CButton>
          <ButtonDelete
            @delete="rowDelete(item)"
            v-if="rowModifiers.hasOwnProperty('delete')"
          >
            {{ render(rowModifiers.delete.body, item) }}
          </ButtonDelete>
          <slot name="rowModifiers" :item="item"></slot>
        </td>
      </template>
    </CSmartTable>
    <CRow>
      <CCol>
        <CSmartPagination
          v-if="pages > 1"
          :activePage="page"
          :pages="pages"
          @activePageChange="(page) => activePageChange(page)"
        />
      </CCol>
      <CCol xs="auto" class="me-auto">
        <CRow>
          <CFormLabel class="col-auto col-form-label"
            >{{ $t('items_per_page') }}:</CFormLabel
          >
          <CCol xs="auto" class="me-auto">
            <CFormSelect
              :options="[
                { label: '5', value: '5' },
                { label: '10', value: '10' },
                { label: '20', value: '20' },
                { label: '50', value: '50' },
              ]"
              :modelValue="per_page_string"
              @update:modelValue="
                (items_per_page) => itemsPerPageChange(items_per_page)
              "
            >
            </CFormSelect>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from 'vue'
// import apiClient from '@/services/apiClient'
// import { mergeRelations } from '@/composables/jsonApi'
import { useStore } from 'vuex'
import ButtonDelete from '@/components/button/Delete.vue'
import { parseDate } from '@/composables/date'

export default {
  name: 'GenericTable',
  components: { ButtonDelete },
  props: {
    apiUrl: { type: String, required: true },
    columns: { type: Array, required: true },
    selectable: { type: Boolean, default: false },
    cleaner: { type: Boolean, default: false },
    itemsPerPage: { type: Number, default: 10 },
    rowModifiers: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  setup(props) {
    let actionsColumn = props.columns.find((column) => column.key === 'actions')
    const ccolumns = ref(props.columns)
    if (actionsColumn == undefined && props.rowModifiers != {}) {
      ccolumns.value.push({
        key: 'actions',
        label: '',
        _style: { width: Object.keys(props.rowModifiers).length * 60 + 'px' },
        _props: {},
        filter: false,
        sorter: false,
      })
    }

    const table = reactive({
      data: [],
      per_page: props.itemsPerPage,
      per_page_string: props.itemsPerPage.toString(),
      page: 1,
      pages: 1,
      loading: true,
      filters: {},
      sort: {},
      reset: Date().toString(),
      filter_reset_disabled: true,
      // response: {},
    })

    const store = useStore()

    // Pagination
    const activePageChange = (page) => {
      if (page <= table.pages && page >= 1) {
        table.page = page
        getData()
      }
    }
    const itemsPerPageChange = (items_per_page) => {
      // TODO: CSmarTable is using :key to force a rerender when changing the items_per_page.
      // CSmartTable should properly listen to the itemsPerPage prop and rerender the content.
      // Using the :key prop makes for example the search fields lose focus on rerender.
      table.per_page = Number(items_per_page)
      table.per_page_string = items_per_page
      activePageChange(1)
    }

    // Filters
    const columnFilterChange = (filters) => {
      table.loading = true
      table.data = []
      table.filters = filters
      activePageChange(1)
      table.filter_reset_disabled = false
    }
    const sorterChange = (sort) => {
      table.sort = sort
      getData()
      table.filter_reset_disabled = false
    }
    const resetFilters = () => {
      if (!table.filter_reset_disabled) {
        table.filters = {}
        table.sort = {}
        table.reset = Date().toString()
        getData()
        table.filter_reset_disabled = true
      }
    }

    // Data
    const getData = async () => {
      table.loading = true
      try {
        const response = await store.dispatch('jv/get', [
          props.apiUrl,
          { params: getRequestParams() },
        ])
        table.per_page = response._jv.json.meta.page.perPage
        table.page = response._jv.json.meta.page.currentPage
        table.pages = response._jv.json.meta.page.lastPage
        delete response._jv
        table.data = Object.values(response)
      } catch (e) {
        console.log('Generic table get error')
        // console.log(e)
      }
      table.loading = false
    }
    const getRequestParams = () => {
      var requestParams = {}

      requestParams['page[size]'] = table.per_page
      requestParams['page[number]'] = table.page
      for (const filter in table.filters) {
        const filterAttribute = props.columns.find(
          (o) => o.key === filter,
        ).attribute
        if (table.filters[filter] != '') {
          if (filterAttribute != undefined) {
            requestParams[
              'filter[' + filterAttribute.split('.').join('][') + ']'
            ] = table.filters[filter]
          } else {
            requestParams['filter[' + filter + ']'] = table.filters[filter]
          }
        }
      }
      if (table.sort.column != undefined && table.sort.column != '') {
        requestParams.sort = ''
        if (table.sort.state == 'desc') {
          requestParams.sort = '-'
        }

        const sortAttribute = props.columns.find(
          (o) => o.key === table.sort.column,
        ).attribute
        if (sortAttribute != undefined) {
          requestParams.sort += sortAttribute
        } else {
          requestParams.sort += table.sort.column
        }
        // if table.sort.state == 0 no sorting must be applied
      }

      return requestParams
    }
    onMounted(async () => {
      getData()
    })

    const rowDelete = (item) => {
      store
        .dispatch('jv/delete', item)
        .then((data) => {
          // if (data == props.apiUrl + props.id) {
          if (
            data != undefined &&
            data._jv.type == item._jv.type &&
            data._jv.id == item._jv.id
          ) {
            table.data.splice(item._id, 1)
            getData()
          }
        })
        .catch(() => {
          console.log('delete row from table error')
        })
    }

    const render = (data, item) => {
      if (typeof data === 'function') {
        return data(item)
      } else if (typeof data === 'string') {
        return data
      }
      return ''
    }

    return {
      ccolumns,
      activePageChange,
      itemsPerPageChange,
      columnFilterChange,
      sorterChange,
      resetFilters,
      getData,
      ...toRefs(table),
      debounce: createDebounce(),
      rowDelete,
      render,
      parseDate,
    }
  },
}

function createDebounce() {
  let timeout = null
  return function (fnc, delayMs) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fnc()
    }, delayMs || 500)
  }
}
</script>
