import { userPreferencesStore } from '@/store/userPreferencesStore'
import { App, Plugin, ref, watch } from 'vue'

export const PreferencesPlugin: Plugin = {
  install: (app: App) => {
    const upStore = userPreferencesStore()

    // const liveAccountId = (live_account_id: string) => {
    const liveAccountId = () => {
      return app.config.globalProperties.$liveAccountId.value
    }

    app.provide('liveAccountId', liveAccountId)

    app.config.globalProperties.$liveAccountId = ref(upStore.live_account_id)

    // const setLiveAccountId = (live_account_id: string) => {
    //   console.log('set', live_account_id)
    //   app.config.globalProperties.$liveAccountId.value = live_account_id
    //   console.log('val', app.config.globalProperties.$liveAccountId.value)
    // }

    // app.provide('setLiveAccountId', setLiveAccountId)

    watch(
      () => app.config.globalProperties.$liveAccountId.value,
      (lac) => {
        if (upStore._live_account_id != lac) upStore._live_account_id = lac
      },
    )

    watch(
      () => upStore._live_account_id,
      (lac) => {
        if (app.config.globalProperties.$liveAccountId.value != lac)
          app.config.globalProperties.$liveAccountId.value = lac
      },
    )
  },
}
