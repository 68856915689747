<template>
  <AppToast />
  <AccountSelect />
  <AppSidebar />
  <div
    class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent"
  >
    <AppHeader />
    <div class="body flex-grow-1 px-3">
      <CContainer lg :key="reloadBody">
        <router-view />
      </CContainer>
    </div>
    <!--   <AppFooter /> -->
  </div>
  <AppAside />
</template>
<script lang="ts">
import { CContainer } from '@coreui/vue-pro'
import AppAside from '@/components/AppAside.vue'
//import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import AppToast from '@/components/AppToast.vue'
import AccountSelect from '@/components/account/Select.vue'
import { authStore } from '@/store/authStore'
import { storeToRefs } from 'pinia'

export default {
  name: 'DefaultLayout',
  components: {
    AppAside,
    //AppFooter,
    AppHeader,
    AppSidebar,
    AppToast,
    AccountSelect,
    CContainer,
  },

  setup() {
    const store = authStore()
    // const reloadBody = ref(store.reloadBody)
    const { reloadBody } = storeToRefs(store)

    return {
      reloadBody: reloadBody,
      // reloadBody: watch(() => store.reloadBody,)
    }
  },
}
</script>
