<template>
  <CToaster placement="top-end" :visible="true">
    <CToast
      v-for="(toast, index) in toasts"
      :key="index"
      class="border-start-4"
      :class="'border-start-'.concat(toast.color)"
      :visible="true"
    >
      <CToastHeader closeButton>
        <span class="me-auto fw-bold">{{ toast.title }}</span>
        <small>{{ toast.when }}</small>
      </CToastHeader>
      <CToastBody>
        {{ toast.content }}
      </CToastBody>
    </CToast>
  </CToaster>
</template>

<script>
import { inject, onMounted, onUnmounted } from 'vue'
import apiClient from '@/services/apiClient'

export default {
  name: 'AppToast',
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const getToasts = inject('getToasts')
    const toasts = getToasts()
    let interceptorId

    const toast = inject('toast')

    onMounted(() => {
      toasts.value.length = 0

      interceptorId = apiClient.interceptors.response.use(
        (response) => {
          return response
        },
        function (error) {
          if (
            Object.prototype.hasOwnProperty.call(error.response.data, 'errors')
          ) {
            error.response.data.errors.forEach((errorItem) => {
              if (errorItem.status != 401) {
                toast({
                  title: errorItem.status + ': ' + errorItem.title,
                  // content: error.response.config.url + ' ' + errorItem.detail,
                  content: errorItem.detail,
                  color: 'danger',
                })
              }
            })
          }
          return Promise.reject(error)
        },
      )
    })
    onUnmounted(() => {
      apiClient.interceptors.response.eject(interceptorId)
    })

    return {
      toasts,
    }
  },
}
</script>
