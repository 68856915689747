import {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibGoogle,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibCcStripe,
  cibCcPaypal,
  cibCcApplePay,
  cibCcAmex,
  cibProbot,
} from '@coreui/icons'
import {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl,
  cifNl,
  cifGb,
} from '@coreui/icons'
import {
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilArrowLeft,
  cilBan,
  cilBasket,
  cilBell,
  cilBookmark,
  cilBold,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilFile,
  cilGlobeAlt,
  cilGrid,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilItalic,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMediaPlay,
  cilMenu,
  cilMoon,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilXCircle,
  cilBrowser,
  cilPlus,
  cilX,
  cilFilterX,
  cilFolderOpen,
  cilDelete,
  cilPhone,
  cilClock,
  cilFingerprint,
} from '@coreui/icons'

import {
  cilGlobe,
  cilCloud,
  cilScreenDesktop,
  cilBox,
  cilRestore,
  cilDns,
  cidLoopSquare,
  cilMail,
  cilFilePdf,
  cisPlus,
  cilPlusCircle,
  cilMinusCircle,
  cisCheck,
  cidUserSecret,
  cilBuildingBusiness,
  cisMail,
  cidMail,
  cisUser,
  cilNote,
  cilCopy,
  cilContactPhone,
  cilContactMail,
} from '@coreui/icons-pro'

export const iconsSet = Object.assign(
  {},
  {
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilArrowLeft,
    cilBan,
    cilBasket,
    cilBell,
    cilBookmark,
    cilBold,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEuro,
    cilFile,
    cilGlobeAlt,
    cilGrid,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilItalic,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMediaPlay,
    cilMenu,
    cilMoon,
    cilOptions,
    cilPaperPlane,
    cilPaperclip,
    cilPencil,
    cilPeople,
    cilPrint,
    cilPuzzle,
    cilSave,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTask,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilBrowser,
    cilPlus,
    cilX,
    cilFilterX,
    cilFolderOpen,
    cilDelete,
    cilPhone,
    cilClock,
    cilFingerprint,
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl,
    cifNl,
    cifGb,
  },
  {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibGoogle,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibCcStripe,
    cibCcPaypal,
    cibCcApplePay,
    cibCcAmex,
    cibProbot,
  },
  {
    cilGlobe,
    cilCloud,
    cilScreenDesktop,
    cilBox,
    cilRestore,
    cilDns,
    cidLoopSquare,
    cilMail,
    cilFilePdf,
    cisPlus,
    cilPlusCircle,
    cilMinusCircle,
    cisCheck,
    cidUserSecret,
    cilBuildingBusiness,
    cisMail,
    cidMail,
    cisUser,
    cilNote,
    cilCopy,
    cilContactPhone,
    cilContactMail,
  },
)
