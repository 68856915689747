import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CIcon = _resolveComponent("CIcon")!
  const _component_CDropdownToggle = _resolveComponent("CDropdownToggle")!
  const _component_CDropdownItem = _resolveComponent("CDropdownItem")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CDropdownDivider = _resolveComponent("CDropdownDivider")!
  const _component_CDropdownMenu = _resolveComponent("CDropdownMenu")!
  const _component_CDropdown = _resolveComponent("CDropdown")!

  return (_openBlock(), _createBlock(_component_CDropdown, {
    variant: "nav-item",
    color: "primary",
    direction: "dropend"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode(_component_CDropdownToggle, {
          placement: "bottom-end",
          class: "py-0",
          caret: false,
          href: "javascript:void(0);"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CIcon, {
              icon: "cis-user",
              size: "lg"
            })
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_CDropdownMenu, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: {
          name: 'UserProfile',
        },
            custom: ""
          }, {
            default: _withCtx(({ href, navigate, isActive }) => [
              _createVNode(_component_CDropdownItem, {
                active: isActive,
                href: href,
                onClick: navigate
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_CIcon, { icon: "cil-user" }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('profile')), 1)
                  ])
                ]),
                _: 2
              }, 1032, ["active", "href", "onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_CDropdownDivider),
          _createVNode(_component_CDropdownItem, { onClick: $setup.logout }, {
            default: _withCtx(() => [
              _createVNode(_component_CIcon, { icon: "cil-lock-locked" }),
              _createTextVNode(" Logout ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 3
  }))
}